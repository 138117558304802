import React from "react"
import styled from "@emotion/styled"
import { keyframes } from "@emotion/react"
import Noise from "./Noise"
import backgroundNoise from "../../static/noise.webp"
import backgroundPhoto from "../../static/new-bg-dp.webp"
import foregroundPhoto from "../../static/new-bg-dp-tl.webp"
import backgroundMobilePhoto from "../../static/new-bg-dp.webp"
import foregroundMobilePhoto from "../../static/new-bg-dp-tl.webp"

import * as backgroundStyles from "../styles/components/background.module.scss"

const BandBackgroundLayer = styled.div`
  position: absolute;
  -webkit-filter: sepia(35%);
  background: url(${backgroundPhoto});
  background-size: cover;
  background-position: center calc(50% + 50px);;
  height: 100%;
  width: 100%;
  overflow: hidden;
  top: 45px;
  z-index: -10;

  @media screen and (max-width: 1000px) {
    background-position: center !important;
  }
`

const BandForegroundLayer = styled.div`
  position: absolute;
  -webkit-filter: sepia(45%);
  background: url(${foregroundPhoto});
  background-size: cover;
  background-position: center calc(50% + 50px);
  opacity: 0.9;
  height: 100%;
  width: 100%;
  overflow: hidden;
  top: 45px;
  z-index: -10;

  @media screen and (max-width: 1000px) {
    background-position: center !important;
  }
`

const GradientLayer = styled.div`
  position: absolute;
  background-size: cover;
  height: 100%;
  width: 100%;
  overflow: hidden;
  background-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0),
    rgba(18, 18, 18, 1)
  );
  z-index: -10;
`

const noiseKeyFrames = keyframes`
  0% {
    transform:translateX(0,0)
  }
  10% {
    transform:translate(-100px,100px)
  }
  20% {
    transform:translate(150px,-100px)
  }
  30% {
    transform:translate(-100px,100px)
  }
  40% {
    transform:translate(100px,-150px)
  }
  50% {
    transform:translate(-100px,200px)
  }
  60% {
    transform:translate(-200px,-100px)
  }
  70% {
    transform:translateY(50px,100px)
  }
  80% {
    transform:translate(100px,-150px)
  }
  90% {
    transform:translate(0,200px)
  }
  100% {
    transform:translate(-100px,100px)
  }
 `

const NoiseLayer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  pointer-events: none;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  overflow: hidden;
  z-index: -10;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: radial-gradient(
      ellipse at center,
      rgba(0, 0, 0, 0) 0,
      rgba(0, 0, 0, 0.75) 100%
    );
  }
`

const NoiseDesktop = styled.div`
  position: absolute;
  top: -500px;
  right: -500px;
  bottom: -500px;
  left: -500px;
  background: transparent url(${backgroundNoise}) 0 0;
  background-size: auto;
  opacity: 0.35;
  animation: ${noiseKeyFrames} 1s steps(8, end) infinite both;
  overflow: hidden;
  pointer-events: none;
  z-index: -10;
`

const NoiseMobile = styled.div`
  position: absolute;
  top: -500px;
  right: -500px;
  bottom: -500px;
  left: -500px;
  background: transparent url(${backgroundNoise}) 0 0;
  background-size: auto;
  opacity: 0.25;
  animation: ${noiseKeyFrames} 1s steps(8, end) infinite both;
  overflow: hidden;
`

const NoiseMobileLayer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  pointer-events: none;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  overflow: hidden;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: radial-gradient(
      ellipse at center,
      rgba(0, 0, 0, 0) 0,
      rgba(0, 0, 0, 0.75) 100%
    );
  }
`

const GradientUpperMobileLayer = styled.div`
  position: absolute;
  background-size: cover;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  background-image: linear-gradient(
    to top,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0),
    rgba(18, 18, 18) 99%
  );
  z-index: -10;
`

const GradientLowerMobileLayer = styled.div`
  position: absolute;
  background-size: cover;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  background-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0),
    rgba(18, 18, 18, 1) 95%
  );
  z-index: -10;
`

const BandForegroundMobileLayer = styled.div`
  position: absolute;
  -webkit-filter: sepia(45%);
  background: url(${foregroundMobilePhoto});
  background-size: auto 100%;
  background-position: center;
  background-repeat: no-repeat;
  margin-top: -30px;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  z-index: -10;
`

const NoBackgroundNoiseLayer = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  pointer-events: none;
  width: 100%;
  height: 100%;
  opacity: 1;
  background-color: #666;
  overflow: hidden;
  z-index: -10;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-position: center;
    background: radial-gradient(
      ellipse at center,
      rgba(0, 0, 0, 0.875) 0,
      rgba(0, 0, 0, 0.975) 100%
    );
  }
`

const BandUpfrontMobileLayer = styled.div`
  position: absolute;
  background: url(${foregroundMobilePhoto});
  -webkit-filter: sepia(35%);
  background-size: auto 100%;
  background-position: center;
  background-repeat: no-repeat;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  opacity: 0.15;
  margin-top: -30px;
  z-index: -10;
`

const BandBackgroundMobileLayer = styled.div`
  position: absolute;
  background: url(${backgroundMobilePhoto});
  -webkit-filter: sepia(35%);
  background-size: auto 100%;
  background-position: center;
  background-repeat: no-repeat;
  margin-top: -30px;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  z-index: -10;
`

export default function Background(props) {
  if (props.hideBandPicture) {
    return (
      <NoBackgroundNoiseLayer>
        <Noise />
      </NoBackgroundNoiseLayer>
    )
  } else {
    return (
      <>
        <div className={backgroundStyles.backgroundMobile__layer}>
          <BandBackgroundMobileLayer />
          <BandForegroundMobileLayer />
          <GradientLowerMobileLayer />
          <NoiseMobileLayer>
            <NoiseMobile />
          </NoiseMobileLayer>
          <BandUpfrontMobileLayer />
        </div>
        <div className={backgroundStyles.background__layer}>
          <BandBackgroundLayer />
          <BandForegroundLayer />
          <NoiseLayer>
            <NoiseDesktop />
          </NoiseLayer>
          <GradientLayer />
        </div>
      </>
    )
  }
}
